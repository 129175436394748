import type { SchemaMarkupConfig } from '@config/schemaMarkup/type';
import { SITE_CONFIG } from '@config/siteConfig';

const BASE_URL = `https://${SITE_CONFIG.metadata.siteURL}`;

export const SCHEMA_MARKUP_CONFIG: SchemaMarkupConfig = {
    webPage: {
        '@type': 'WebPage',
        url: BASE_URL,
        isPartOf: {
            '@type': 'WebSite',
            name: 'Storia.ro',
        },
        about: {
            '@type': 'Organization',
            foundingDate: '2016',
            logo: `${SITE_CONFIG.metadata.preconnectStaticsCDN}/fp_statics${SITE_CONFIG.images.logo}`,
            brand: 'OLX Group',
            alternateName: [
                'storia ro',
                'storia.ro',
                'storia olx',
                'storia imobiliare',
                'storia. ro',
                'storia romania',
                'www.storia.ro',
                'storia apartamente',
                'storya',
                'storia.ro imobiliare',
                'storia imobiliare bucuresti',
                'storia bucuresti',
                'imobiliare storia',
                'storia agentie imobiliara',
            ],
            sameAs: [
                'https://www.facebook.com/storia.ro/',
                'https://www.instagram.com/storia_ro/?hl=en',
                'https://twitter.com/storia_ro',
                'https://www.youtube.com/@Storiaro',
                'https://ro.linkedin.com/company/storia-ro',
                'https://apps.apple.com/es/app/storia-anunturi-imobiliare/id1121617948',
                'https://play.google.com/store/apps/details',
            ],
            address: {
                '@type': 'PostalAddress',
                addressLocality: 'București',
                postalCode: '011141',
                addressRegion: 'București',
                streetAddress: 'Șoseaua Nicolae Titulescu 4-8, etaj 5',
                addressCountry: {
                    '@type': 'Country',
                    name: 'România',
                },
            },
            contactPoint: {
                '@type': 'ContactPoint',
                telephone: '+40 31 630 13 76',
                areaServed: 'România',
                contactType: 'customer service',
                availableLanguage: 'Română',
            },
        },
    },
    searchAction: {
        '@type': 'WebSite',
        url: BASE_URL,
        potentialAction: {
            '@type': 'SearchAction',
            'query-input': 'required name=search_term_string',
        },
    },
    product: {
        '@type': 'Product',
        additionalType: 'RealEstateListing',
        url: BASE_URL,
        adUrlPrefix: '/[lang]/oferta/',
        addressCountry: {
            '@type': 'Country',
            name: 'România',
        },
    },
};
