import { SEARCH_BOX_ROW_LAYOUT, SEARCH_FORM_FIELDS_LAYOUT } from '@config/consts/searchBox/layout/home/storiaro';
import type { HomeConfig } from '@config/home/type';
import { SITE_CONFIG } from '@config/siteConfig';

export const HOME_CONFIG: HomeConfig = {
    canonicalURL: 'https://www.storia.ro',
    searchBoxRows: SEARCH_BOX_ROW_LAYOUT,
    searchBoxFieldsLayout: SEARCH_FORM_FIELDS_LAYOUT,
    seoSchemaMarkupData: {
        potentialAction: {
            target: '/[lang]/rezultate/vanzare/apartament/toata-romania',
        },
        primaryImageOfPage: {
            url: `${SITE_CONFIG.metadata.preconnectStaticsCDN}/fp_statics${SITE_CONFIG.images.homepageBackground}`,
            width: '1920',
            height: '552',
        },
    },
    isNexusHomePageAvailable: false,
    searchSection: {
        isGradientBackground: false,
    },
    faqSectionItems: [
        {
            id: 'faq-01',
            questionKey: 'frontend.homepage.storia-faq-section.question-1',
            answerKey: 'frontend.homepage.storia-faq-section.answer-1',
        },
        {
            id: 'faq-02',
            questionKey: 'frontend.homepage.storia-faq-section.question-2',
            answerKey: 'frontend.homepage.storia-faq-section.answer-2',
        },
        {
            id: 'faq-03',
            questionKey: 'frontend.homepage.storia-faq-section.question-3',
            answerKey: 'frontend.homepage.storia-faq-section.answer-3',
        },
        {
            id: 'faq-04',
            questionKey: 'frontend.homepage.storia-faq-section.question-4',
            answerKey: 'frontend.homepage.storia-faq-section.answer-4',
        },
        {
            id: 'faq-05',
            questionKey: 'frontend.homepage.storia-faq-section.question-5',
            answerKey: 'frontend.homepage.storia-faq-section.answer-5',
        },
        {
            id: 'faq-06',
            questionKey: 'frontend.homepage.storia-faq-section.question-6',
            answerKey: 'frontend.homepage.storia-faq-section.answer-6',
        },
        {
            id: 'faq-07',
            questionKey: 'frontend.homepage.storia-faq-section.question-7',
            answerKey: 'frontend.homepage.storia-faq-section.answer-7',
        },
        {
            id: 'faq-08',
            questionKey: 'frontend.homepage.storia-faq-section.question-8',
            answerKey: 'frontend.homepage.storia-faq-section.answer-8',
        },
        {
            id: 'faq-09',
            questionKey: 'frontend.homepage.storia-faq-section.question-9',
            answerKey: 'frontend.homepage.storia-faq-section.answer-9',
        },
    ],
    propertiesForSellSection: [
        {
            id: 'sell-București',
            imageUrl: '/images/homepage/popularLocationsSection/storiaro/popularLocationsBucuresti.webp',
            header: 'București',
            location: 'bucuresti',
            translatedLocation: {
                default: 'București',
            },
            cityLandingLocation: 'bucuresti',
        },
        {
            id: 'sell-Iași',
            imageUrl: '/images/homepage/popularLocationsSection/storiaro/popularLocationsIasi.webp',
            header: 'Iași',
            location: 'iasi',
            translatedLocation: {
                default: 'Iași',
            },
            cityLandingLocation: 'iasi',
        },
        {
            id: 'sell-Cluj-Napoca',
            imageUrl: '/images/homepage/popularLocationsSection/storiaro/popularLocationsClujNapoca.webp',
            header: 'Cluj-Napoca',
            location: 'cluj/cluj--napoca',
            translatedLocation: {
                default: 'Cluj-Napoca',
            },
            cityLandingLocation: 'cluj--napoca',
        },
        {
            id: 'sell-Timisoara',
            imageUrl: '/images/homepage/popularLocationsSection/storiaro/popularLocationsTimisoara.webp',
            header: 'Timișoara',
            location: 'timis/timisoara',
            translatedLocation: {
                default: 'Timișoara',
            },
            cityLandingLocation: 'timisoara',
        },
        {
            id: 'sell-Constanta',
            imageUrl: '/images/homepage/popularLocationsSection/storiaro/popularLocationsConstanta.webp',
            header: 'Constanța',
            location: 'constanta',
            translatedLocation: {
                default: 'Constanța',
            },
            cityLandingLocation: 'constanta',
        },
        {
            id: 'sell-Craiova',
            imageUrl: '/images/homepage/popularLocationsSection/storiaro/popularLocationsCraiova.webp',
            header: 'Craiova',
            location: 'dolj/craiova',
            translatedLocation: {
                default: 'Craiova',
            },
            cityLandingLocation: 'craiova',
        },
        {
            id: 'sell-Brașov',
            imageUrl: '/images/homepage/popularLocationsSection/storiaro/popularLocationsBrasov.webp',
            header: 'Brașov',
            location: 'brasov',
            translatedLocation: {
                default: 'Brașov',
            },
            cityLandingLocation: 'brasov',
        },
        {
            id: 'sell-Galați',
            imageUrl: '/images/homepage/popularLocationsSection/storiaro/popularLocationsGalati.webp',
            header: 'Galați',
            location: 'galati',
            translatedLocation: {
                default: 'Galați',
            },
            cityLandingLocation: 'galati',
        },
    ],
    propertiesForRentSection: [
        {
            id: 'rent-București',
            imageUrl: '/images/homepage/popularLocationsSection/storiaro/popularLocationsBucuresti.webp',
            header: 'București',
            location: 'bucuresti',
            translatedLocation: {
                default: 'București',
            },
            cityLandingLocation: 'bucuresti',
        },
        {
            id: 'rent-Iași',
            imageUrl: '/images/homepage/popularLocationsSection/storiaro/popularLocationsIasi.webp',
            header: 'Iași',
            location: 'iasi',
            translatedLocation: {
                default: 'Iași',
            },
            cityLandingLocation: 'iasi',
        },
        {
            id: 'rent-Cluj-Napoca',
            imageUrl: '/images/homepage/popularLocationsSection/storiaro/popularLocationsClujNapoca.webp',
            header: 'Cluj-Napoca',
            location: 'cluj/cluj--napoca',
            translatedLocation: {
                default: 'Cluj-Napoca',
            },
            cityLandingLocation: 'cluj--napoca',
        },
        {
            id: 'rent-Timisoara',
            imageUrl: '/images/homepage/popularLocationsSection/storiaro/popularLocationsTimisoara.webp',
            header: 'Timișoara',
            location: 'timis/timisoara',
            translatedLocation: {
                default: 'Timișoara',
            },
            cityLandingLocation: 'timisoara',
        },
        {
            id: 'rent-Constanta',
            imageUrl: '/images/homepage/popularLocationsSection/storiaro/popularLocationsConstanta.webp',
            header: 'Constanța',
            location: 'constanta',
            translatedLocation: {
                default: 'Constanța',
            },
            cityLandingLocation: 'constanta',
        },
        {
            id: 'rent-Craiova',
            imageUrl: '/images/homepage/popularLocationsSection/storiaro/popularLocationsCraiova.webp',
            header: 'Craiova',
            location: 'dolj/craiova',
            translatedLocation: {
                default: 'Craiova',
            },
            cityLandingLocation: 'craiova',
        },
        {
            id: 'rent-Brașov',
            imageUrl: '/images/homepage/popularLocationsSection/storiaro/popularLocationsBrasov.webp',
            header: 'Brașov',
            location: 'brasov',
            translatedLocation: {
                default: 'Brașov',
            },
            cityLandingLocation: 'brasov',
        },
        {
            id: 'rent-Galați',
            imageUrl: '/images/homepage/popularLocationsSection/storiaro/popularLocationsGalati.webp',
            header: 'Galați',
            location: 'galati',
            translatedLocation: {
                default: 'Galați',
            },
            cityLandingLocation: 'galati',
        },
    ],
    propertiesFromPrimaryMarketSection: [
        {
            id: 'primary-market-București',
            imageUrl: '/images/homepage/popularLocationsSection/storiaro/popularLocationsBucuresti.webp',
            header: 'București',
            location: 'bucuresti',
            translatedLocation: {
                default: 'București',
            },
            cityLandingLocation: 'bucuresti',
        },
        {
            id: 'primary-market-Iași',
            imageUrl: '/images/homepage/popularLocationsSection/storiaro/popularLocationsIasi.webp',
            header: 'Iași',
            location: 'iasi',
            translatedLocation: {
                default: 'Iași',
            },
            cityLandingLocation: 'iasi',
        },
        {
            id: 'primary-market-Cluj-Napoca',
            imageUrl: '/images/homepage/popularLocationsSection/storiaro/popularLocationsClujNapoca.webp',
            header: 'Cluj-Napoca',
            location: 'cluj/cluj--napoca',
            translatedLocation: {
                default: 'Cluj-Napoca',
            },
            cityLandingLocation: 'cluj--napoca',
        },
        {
            id: 'primary-market-Timisoara',
            imageUrl: '/images/homepage/popularLocationsSection/storiaro/popularLocationsTimisoara.webp',
            header: 'Timișoara',
            location: 'timis/timisoara',
            translatedLocation: {
                default: 'Timișoara',
            },
            cityLandingLocation: 'timisoara',
        },
        {
            id: 'primary-market-Constanta',
            imageUrl: '/images/homepage/popularLocationsSection/storiaro/popularLocationsConstanta.webp',
            header: 'Constanța',
            location: 'constanta',
            translatedLocation: {
                default: 'Constanța',
            },
            cityLandingLocation: 'constanta',
        },
        {
            id: 'primary-market-Craiova',
            imageUrl: '/images/homepage/popularLocationsSection/storiaro/popularLocationsCraiova.webp',
            header: 'Craiova',
            location: 'dolj/craiova',
            translatedLocation: {
                default: 'Craiova',
            },
            cityLandingLocation: 'craiova',
        },
        {
            id: 'primary-market-Brașov',
            imageUrl: '/images/homepage/popularLocationsSection/storiaro/popularLocationsBrasov.webp',
            header: 'Brașov',
            location: 'brasov',
            translatedLocation: {
                default: 'Brașov',
            },
            cityLandingLocation: 'brasov',
        },
        {
            id: 'primary-market-Galați',
            imageUrl: '/images/homepage/popularLocationsSection/storiaro/popularLocationsGalati.webp',
            header: 'Galați',
            location: 'galati',
            translatedLocation: {
                default: 'Galați',
            },
            cityLandingLocation: 'galati',
        },
    ],
    pinterestVerifyCode: 'b7fedf1a1fe6f07827d9404e065dcc5f',
    usabilitySection: {
        hasBackground: true,
    },
    primaryMarketSection: {
        flatIconPath: '/images/homepage/primaryMarketSection/storiaFlat.svg',
        commercialIconPath: '',
        availableSubSection: ['flat', 'house'],
    },
    mortgageCalculatorSection: {
        linkUrl: 'https://creditare.storia.ro/',
        image: {
            src: '/images/homepage/mortgageCalculatorSection/storiaro/mortgageCalculatorImage.webp',
            height: 460,
        },
    },
    sellersSection: {
        analyze: {
            linkUrl: 'https://www.storia.ro/blog/tag/agent-expert',
            trackingData: {
                touchPointButton: 'for_professionals_analytics',
            },
        },
        improve: {
            linkUrl: 'https://www.youtube.com/watch?v=11wscQCxtO8&list=PLSiRSrUqtEUh7LGI36r_SAP3kyB2ct2Dd',
            trackingData: {
                touchPointButton: 'for_professionals_webinars',
            },
        },
        explore: {
            linkUrl: 'https://www.storia.ro/ro/preturi/agentii',
            trackingData: {
                touchPointButton: 'for_professionals_packages',
            },
        },
    },
    analyticsSection: {
        linkUrl: 'https://trai.storia.ro/',
        imagePath: {
            desktop: '/images/homepage/analyticsSection/storia-analytics-desktop.webp',
            mobile: '/images/homepage/analyticsSection/storia-analytics-mobile.webp',
        },
    },
    servicesSection: [
        {
            id: '1',
            icon: '/images/homepage/servicesSection/storiaro/traiIcon.svg',
            backgroundImage: '/images/homepage/servicesSection/storiaro/traiBackground.webp',
            headline: 'frontend.homepage.services-section.storia-trai-headline',
            description: 'frontend.homepage.services-section.storia-trai-description-content',
            link: {
                label: 'frontend.homepage.services-section.storia-trai-link-label',
                href: 'https://trai.storia.ro/',
                trackEventName: 'seo_link_click',
                touchPointButton: 'services_trai',
            },
        },
        {
            id: '2',
            icon: '/images/homepage/servicesSection/storiaro/financeIcon.svg',
            backgroundImage: '/images/homepage/servicesSection/storiaro/financeBackground.webp',
            headline: 'frontend.homepage.services-section.storia-finance-headline',
            description: 'frontend.homepage.services-section.storia-finance-description-content',
            link: {
                label: 'frontend.homepage.services-section.storia-finance-link-label',
                href: 'https://creditare.storia.ro/',
                trackEventName: 'finance_click',
                touchPointButton: 'services_finance',
            },
        },
    ],
    nexusUsabilitySection: {
        forSeekers: [
            {
                id: '1',
                headline: 'frontend.homepage.nexus-usability-section-for-seekers.tile-1-headline',
                description: 'frontend.homepage.nexus-usability-section-for-seekers.tile-1-description',
                link: {
                    label: 'frontend.homepage.nexus-usability-section-for-seekers.tile-1-link-label',
                    href: '/[lang]/rezultate/vanzare/apartament/toata-romania',
                    trackingEventName: 'seo_link_click',
                    touchPointButton: 'why_seekers_sell',
                },
                image: '/images/homepage/nexusUsabilitySection/tileImage7.webp',
            },
            {
                id: '2',
                headline: 'frontend.homepage.nexus-usability-section-for-seekers.tile-2-headline',
                description: 'frontend.homepage.nexus-usability-section-for-seekers.tile-2-description',
                link: {
                    label: 'frontend.homepage.nexus-usability-section-for-seekers.tile-2-link-label',
                    href: '/anunt-nou/categorie/',
                    trackingEventName: 'posting_form_click',
                    touchPointButton: 'why_seekers',
                },
                image: '/images/homepage/nexusUsabilitySection/tileImage8.webp',
            },
            {
                id: '3',
                headline: 'frontend.homepage.nexus-usability-section-for-seekers.tile-3-headline',
                description: 'frontend.homepage.nexus-usability-section-for-seekers.tile-3-description',
                link: {
                    label: 'frontend.homepage.nexus-usability-section-for-seekers.tile-3-link-label',
                    href: '/[lang]/rezultate/inchiriere/apartament/toata-romania',
                    trackingEventName: 'seo_link_click',
                    touchPointButton: 'why_seekers_rent',
                },
                image: '/images/homepage/nexusUsabilitySection/tileImage9.webp',
            },
        ],
        forProfessionals: [
            {
                id: '1',
                headline: 'frontend.homepage.nexus-usability-section-for-professionals.tile-1-headline',
                description: 'frontend.homepage.nexus-usability-section-for-professionals.tile-1-description',
                link: {
                    label: 'frontend.homepage.nexus-usability-section-for-professionals.tile-1-link-label',
                    href: 'https://www.storia.ro/blog/tag/agent-expert',
                    trackingEventName: 'seo_link_click',
                    touchPointButton: 'why_professionals_data',
                },
                image: '/images/homepage/nexusUsabilitySection/tileImage10.webp',
            },
            {
                id: '2',
                headline: 'frontend.homepage.nexus-usability-section-for-professionals.tile-2-headline',
                description: 'frontend.homepage.nexus-usability-section-for-professionals.tile-2-description',
                link: {
                    label: 'frontend.homepage.nexus-usability-section-for-professionals.tile-2-link-label',
                    href: 'https://www.youtube.com/watch?v=11wscQCxtO8&list=PLSiRSrUqtEUh7LGI36r_SAP3kyB2ct2Dd',
                    trackingEventName: 'seo_link_click',
                    touchPointButton: 'why_professionals_webinars',
                },
                image: '/images/homepage/nexusUsabilitySection/tileImage11.webp',
            },
            {
                id: '3',
                headline: 'frontend.homepage.nexus-usability-section-for-professionals.tile-3-headline',
                description: 'frontend.homepage.nexus-usability-section-for-professionals.tile-3-description',
                link: {
                    label: 'frontend.homepage.nexus-usability-section-for-professionals.tile-3-link-label',
                    href: '/[lang]/preturi/agentii',
                    trackingEventName: 'seo_link_click',
                    touchPointButton: 'why_professionals_packages',
                },
                image: '/images/homepage/nexusUsabilitySection/tileImage12.webp',
            },
        ],
    },
    nexusBrandingSection: {
        image: '/images/homepage/brandingSection/background.webp',
    },
    linkingSection: {
        images: {
            flatsOnSell: '/images/homepage/linkingSection/flatsOnSell.webp',
            housesOnRent: '/images/homepage/linkingSection/housesOnRent.webp',
            housesOnSell: '/images/homepage/linkingSection/housesOnSell.webp',
            terrainsOnSell: '/images/homepage/linkingSection/terrainOnSell.webp',
            commercialPropertiesOnSell: '/images/homepage/linkingSection/commercialPropertiesOnSell.webp',
            flatsOnRent: '/images/homepage/linkingSection/flatsOnRent.webp',
            roomsOnRent: '/images/homepage/linkingSection/roomsOnRent.webp',
            commercialPropertiesOnRent: '/images/homepage/linkingSection/commercialPropertiesOnRent.webp',
        },
    },
};
